

import { Component, Vue } from 'vue-property-decorator'
import KionHeader from '@/components/Header/Header.vue'
import KionFooter from '@/components/Footer/Footer.vue'
import KionCopyright from '@/components/Shared/Copyright.vue'


declare global {
  interface Window {
    kionVersion: string;
  }
}

@Component({ components: { KionHeader, KionFooter, KionCopyright } })
export default class App extends Vue {
  mounted() {
    window.sessionStorage.clear()
    window.kionVersion = 'OLD_21_09_2021-18-20'
  }
}
