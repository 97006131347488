
import { Component, Vue } from 'vue-property-decorator';
import KionTemplate from '@/components/Shared/Template.vue';
import { parseJWT, parseUriHash } from '@/util/HashParser';
import { httpService } from '@/util/HttpAdapter';

@Component({ components: { KionTemplate } })
export default class KionSsoAuth extends Vue {
  async mounted() {
    if (Object.keys(this.$route.query).length === 0 && !this.$route.hash) {
      this.$router.push('/');
    }

    const { state, code, id_token } = parseUriHash(this.$route.hash);

    const { phone, nonce, sub } = parseJWT(id_token);
    httpService.promoCode = state;
    httpService.phone = phone;
    try {
      const { accessToken } = (await httpService.auth(nonce, code)).data;
      httpService.accessToken = accessToken;

      this.$router.push('/success');
    } catch (e) {
      await this.$router.push('/error');
    }
  }
}
