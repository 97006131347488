
import { Component, Vue } from 'vue-property-decorator'
import KionInput from '@/components/Shared/Input/Input.vue'
import KionTemplate from '@/components/Shared/Template.vue'
import Template from '@/components/Shared/Template.vue'
import KionButton from '@/components/Shared/Button.vue'
import { httpService } from '@/util/HttpAdapter'
import { KionErrorType } from '@/views/Error.vue'
import { AxiosResponse } from 'axios'

@Component({
  components: { Template, KionTemplate, KionInput, KionButton }
})
export default class KionSms extends Vue {
  time = 59
  sms = ''
  codeError: string | null = null
  timerCode: number | undefined = undefined
  loading = false

  mounted() {
    this.restartTimer()
    httpService.requestSms().catch(() => this.$router.push('/error'))
  }

  getSMSInputError(errorMsg?: string) {
    this.sms = this.sms.trim()

    if (errorMsg) {
      this.codeError = errorMsg
      return
    }
    if (!this.sms) {
      this.codeError = 'Обязательное поле'
      return
    }
    this.codeError = null
  }

  async next() {
    this.sms = this.sms.trim()

    if(!this.sms) {
      return
    }

    this.loading = true
    try {
      // httpService.accessToken  = (await httpService.auth(this.sms) as AxiosResponse).data.accessToken

      const promoResp = (await httpService.checkPromo() as AxiosResponse).data

      if (promoResp?.voucher?.status === 'NOT_FOUND') {
        await this.$router.push('/promo')
        return
      }
      const subs = promoResp.promoProductPrices

      if (subs.length === 0) {
        await this.$router.push('/error')
        return
      }

      const prices = (await httpService.checkPrice(subs[0].productId) as AxiosResponse)
              .data.productPrices

      if (prices.length === 0) {
        await this.$router.push('/error')
        return
      }

      await httpService.applySubscription(prices[0].discountPrice)
      await this.$router.push('/success')

    } catch (err) {
      this.loading = false
      const errCode = err?.response?.data?.errorCode

      if (err === 'PromoCodeNotFoundException') {
        await this.$router.push('/promo')
        return
      }

      if (errCode === 'ObjectAlreadyExistsException') {
        await this.$router.push('/error')
        console.error('Промокод уже был активирован')
        return
      }

      if (errCode === 'InvalidConfirmationCodeException') {
        this.getSMSInputError('Неверный код. Попробуйте ещё раз')
        return
      }

      if (errCode === 'SubscriberNotFoundException') {
        await this.$router.push(`/error?&type=${ KionErrorType.ACCOUNT_NOT_FOUND }`)
        return
      }

      console.error(errCode)
      await this.$router.push('/error')
    }
  }

  requestCode() {
    this.restartTimer()
    httpService.requestSms().catch(() => this.$router.push('/error'))
  }

  restartTimer() {
    this.time = 59
    this.timerCode = setInterval(() => {
      this.time -= 1
      if (this.time === 0) {
        clearInterval(this.timerCode)
      }
    }, 1000)
  }
}
