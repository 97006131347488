
import { Component, Vue } from 'vue-property-decorator'
import KionAnimatedColumn from '@/components/Footer/AnimatedColumn.vue'
import KionCopyright from '@/components/Shared/Copyright.vue'

@Component({
  components: {
    KionAnimatedColumn,
    KionCopyright
  }
})
export default class KionFooter extends Vue {
  images = this.getImgGroup()


  getImgGroup() {
    const path = '/posters/g-'
    const arr = []
    let subArr = []
    for (let g = 1; g < 6; g++) {
      for (let i = 1; i <= 3; i++) {
        subArr.push(path + `${ g }-${ i }.png`)
      }
      arr.push(subArr)
      subArr = []
    }

    return arr
  }
}
