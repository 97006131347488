

import { Vue } from 'vue-property-decorator'

export default class KionRules extends Vue {
  list = [
    { value: 'Акционное предложение доступно для пользователей сервиса KION.' },
    { value: 'Условие для Акционного подключения действует с 20.04.2021 по 31.08.2021 включительно.' },
    { value: 'Стоимость акционного предложения 0 руб./мес. или 0 рублей/год (в зависимости от типа промокода)' },
    { value: 'По истечении срока, указанного в п.3, подписка на пакет «ShowU» не переходит в автопролонгацию и отключится.' },
    { value: 'Промокоды выдаются 2-х типов: годовой (100% скидка на стоимость пользования сервисом в течение 1 года) и месячный (100% скидка на стоимость пользования сервисом в течение 1 месяца)' },
    { value: 'Промокод по данной акции на 1 телефонный номер можно активировать 1 раз.' },
    { value: 'Количество промокодов ограниченно.' },
    { value: 'Акция действует на всей территории Российской Федерации.' },
    { value: 'К одному профилю можно подключить пять любых устройств: смартфоны, планшеты, компьютеры, Smart TV, Android TV и AppleTV c 4 поколения.' },
    { value: 'При отключении услуги в период льготного предоставления повторное подключение будет невозможно.' },
    { value: 'У абонентов МТС при просмотре KION пакеты мобильного интернета не расходуются. Ограничения скорости на безлимитные опции не распространяются.' },
    { value: 'При использовании услуг Real IP, Static IP и VPN-браузеров трафик тарифицируется по условиям тарифа.' },
    { value: 'При загрузке KION с сайта МТС и регистрации в сети МТС мобильный трафик не тарифицируется на всей территории России.' },
    { value: 'При загрузке KION из App Store, Google Play и других магазинов трафик тарифицируется по условиям тарифа.' },
    { value: 'Если вы находитесь за границей, мобильный интернет при просмотре телеканалов и загрузке KION оплачивается по роуминговым условиям тарифа.' },
    { value: `Использование промокода недоступно на устройствах Apple (AppleTV, iPhone). Для активации промокода рекомендуем использовать сайт <a style="color: var(--text-link)" href="https://kion.ru">https://kion.ru/</a> (вход под своим номером телефона).` },
    { value: 'Полный доступ к контенту KION предоставляется только на территории РФ. За ее пределами объем контента может быть ограничен лицензионными соглашениями с правообладателями.' },
    { value: 'При использовании VPN-сервисов просмотр может быть недоступен или ограничен.' },
    {
      value: 'Качество видео определяется скоростью передачи данных:',
      subList: [
        'минимально необходимая скорость для просмотра — 512 Кбит/с',
        'для просмотра в нормальном качестве — от 1,6 до 6 Мбит/с;',
        'просмотр в максимальном качестве — от 6 Мбит/с HD до 19 Мбит/с UltraHD.'
      ]
    },
    { value: 'Абоненты других операторов мобильной связи могут оплатить подписку на KION с помощью банковской карты. Оплата списывается каждый месяц в день, соответствующий дате подключения, независимо от факта просмотра телеканалов на протяжении всего периода действия услуги до самостоятельного отключения. При неоплате доступ приостанавливается.' },
    { value: 'При загрузке и использовании KION возможны служебные DNS-запросы, которые выполняются автоматически для определения соответствия доменного имени и IP-адреса с использованием незначительного объема служебного трафика. Тарификация таких запросов осуществляется по условиям тарифа.' },
    { value: 'Организаторы акции: ПАО «МТС» ИНН 7740000076, 109147, г. Москва, ул. Марксистская, д.4.' }

  ]

}
