

import { Component, Vue } from 'vue-property-decorator'
import { httpService } from '@/util/HttpAdapter'
import { AxiosResponse } from 'axios'
import KionButton from '@/components/Shared/Button.vue'
import KionRules from '@/templates/Rules.vue'

@Component({
  components: {
    KionButton,
    KionRules
  }
})
export default class KionCopyright extends Vue {

  agreementMarkup = ''
  agreementModalVisible = false
  rulesModalVisible = false

  mounted() {
    document.addEventListener('keydown', this.keydownHandler)
  }

  destroyed() {
    document.removeEventListener('keydown', this.keydownHandler)
  }
  keydownHandler(e: KeyboardEvent) {

    if((this.agreementModalVisible || this.rulesModalVisible) && e.key === "Escape") {
      this.agreementModalVisible = this.rulesModalVisible = false
    }
  }

  async openAgreementModal() {
    this.rulesModalVisible = false
    this.agreementMarkup = (await httpService.getLastOfferMarkup() as AxiosResponse).data.content
    this.agreementModalVisible = true
  }
}

