
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component({})
export default class KionAnimatedColumn extends Vue {
  @Ref('slider') sliderRef!: HTMLDivElement
  @Prop({ default: false }) reversed!: boolean
  @Prop({ default: () => [] }) images!: string[]
  @Prop({ default: 0 }) shift!: number
  currentShift = 100
  count = 2

  mounted() {
    this.sliderRef.addEventListener('transitionend', this.repeatSlide)
    if (this.reversed) {
      this.sliderRef.style.bottom = `${ this.shift }%`
    } else {
      this.sliderRef.style.top = `${ this.shift }%`
    }

    setTimeout(() => this.setNextSlidePosition())
  }

  setNextSlidePosition() {
    if (this.reversed) {
      this.sliderRef.style.bottom = `${ this.currentShift + this.shift }%`
    } else {
      this.sliderRef.style.top = `${ this.currentShift + this.shift }%`
    }
  }

  repeatSlide() {
    this.currentShift += 100
    this.count++
    this.setNextSlidePosition()
  }
}
