import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { v4 as uuidv4 } from 'uuid'

interface AuthResponse {
  userId: string
  accessToken: string
  refreshToken: string
  tokenType: string
  expiresIn: number
}

interface Promo {
  promoCode: string
  productId: string
  discountPrice: number
}

class HttpService {
  private token: null | string = null
  private _phone = ''
  private _promoCode = ''
  private _clientTerminalId = ''
  public promo: Promo = {
    promoCode: '',
    discountPrice: 0,
    productId: ''
  }

  get clientTerminalId(): string {
    return this._clientTerminalId;
  }

  set clientTerminalId(value: string) {
    this._clientTerminalId = value;
  }

  private _http = axios.create({
    baseURL: '/welcome/api'
  })

  private post<T>(
    endpoint: string,
    payload: { [key: string]: unknown } | string = {},
    additionalOptions = {}
  ): Promise<AxiosResponse<T>> {
    let options: AxiosRequestConfig = {
      ...additionalOptions,
      // withCredentials: true
    }

    if (this.token) {
      options = {
        ...options,
        headers: {
          'Authorization': `Bearer ${ this.token }`
        }
      }
    }

    return this._http.post<T>(endpoint, payload, options)
  }

  public getLastOfferMarkup() {
    const options: AxiosRequestConfig = {
      baseURL: '/welcome/offertapi'
    }
    return this.post('/offers/actions/get-last-published-offer', {}, options)
  }

  public checkPromo() {
    const { promoCode } = this.promo
    const options: AxiosRequestConfig = {
      baseURL: '/welcome/subscriptionapi'
    }
    return this.post('/products/actions/get-promo-products', { promoCode }, options)
  }

  public checkPrice(id: string) {
    this.promo.productId = id
    const { promoCode, productId } = this.promo
    const options: AxiosRequestConfig = {
      baseURL: '/welcome/subscriptionapi'
    }
    return this.post('/products/v2/actions/get-adjusted-prices', { promoCode, productIds: [{ productId }] }, options)
  }

  public applySubscription(_price: number) {
    this.promo.discountPrice = _price
    const { promoCode, productId, discountPrice: price } = this.promo
    const options: AxiosRequestConfig = {
      baseURL: '/welcome/subscriptionapi'
    }
    return this.post('/subscriptions', { promoCode, price,  productId  }, options)
  }

  public requestSms(): Promise<AxiosResponse> {
    return this.post(`/general/send-code?msisdn=${ this.phone }`)
  }

  public auth(nonce: string, webssoCode: string): Promise<AxiosResponse<AuthResponse>> {
    const clientTerminalId = this.clientTerminalId = this.phone + '-' + uuidv4();
    return this.post<AuthResponse>(
      '/ott/actions/websso-login',
      {
        clientTerminalId,
        terminalType: '2',
        deviceModel: 'Landing',
        deviceName: 'welcome_kion_ru',
        deviceOS: 'Landing KION',
        refreshTokenSupported: false,
        webssoCode,
        nonce
      }
    );
  }

  public set phone(str: string) {
    this._phone = str
  }

  public get phone(): string {
    return this._phone
  }

  public set promoCode(str: string) {
    this.promo.promoCode = str.toUpperCase()
    this._promoCode = str.toUpperCase()
  }

  public get promoCode(): string {
    return this._promoCode
  }

  public set accessToken(str: string) {
    this.token = str
  }

}

export const httpService = new HttpService()
