
import { Component, Vue } from 'vue-property-decorator'
import KionFooter from '@/components/Footer/Footer.vue'
import KionButton from '@/components/Shared/Button.vue'
import KionTemplate from '@/components/Shared/Template.vue'
import Template from '@/components/Shared/Template.vue'

export enum KionErrorType {
  LIMIT_REACHED = 'limit-reached',
  UNKNOWN_OPERATOR = 'unknown-operator',
  IMPOSSIBLE_TO_CONNECT = 'impossible-to-connect',
  ACCOUNT_NOT_FOUND = 'account-not-found'
}

interface ErrorScheme {
  title: string,
  msg: string,
  btnName: string
  action: () => void
}

@Component({
  components: {
    Template,
    KionFooter,
    KionButton,
    KionTemplate
  }
})
export default class KionError extends Vue {
  errorsMap: Map<KionErrorType, ErrorScheme> = new Map([
    [KionErrorType.LIMIT_REACHED,
      {
        title: 'Превышено число устройств',
        msg: `К учетной записи МТС ТВ можно подключить не более 5 устройств. Удалите устройство и попробуйте снова`,
        btnName: 'Перейти в настройки',
        action: () => this.$router.push('/')
      }
    ],
    [KionErrorType.IMPOSSIBLE_TO_CONNECT,
      {
        title: 'Подключение невозможно',
        msg: `У Вас уже подключен пакет «Онлайн-кинотеатры». Для <br> подключения пакета «Развлекательное» необходимо отключить пакет <br> «Онлайн-кинотеатры». Совместное использование этих пакетов невозможно`,
        btnName: 'Перейти в настройки',
        action: () => this.$router.push('/')
      }
    ],
    [KionErrorType.UNKNOWN_OPERATOR,
      {
        title: 'Неподдерживаемый оператор сотовой связи',
        msg: `Номер телефона принадлежит оператору сотовой связи, который не поддерживается в соответствии с условиями участия в акции. Введите другой номер телефона или приобретите SIM-карту оператора МТС`,
        btnName: 'Показать карту салонов МТС',
        action: () => window.location.href = 'https://moskva.mts.ru/personal/podderzhka/zoni-obsluzhivaniya/offices/'
      }
    ],
    [KionErrorType.ACCOUNT_NOT_FOUND,
      {
        title: 'Телефон не зарегестрирован',
        msg: `Для начала необходимо зарегестрироваться в системе`,
        btnName: 'Зарегестрироваться',
        action: () => window.location.href = 'https://mtstv.mts.ru/home'
      }]
  ])

  error: ErrorScheme = {
    title: '',
    msg: `
    Неизвестная ошибка. Что-то пошло не так. <br>
    Попробуйте вернуться позже или попробовать снова
    `,
    btnName: 'Назад',
    action: () => this.$router.push('/')
  }

  navigate(link: string) {
    this.$router.push({ path: link })
  }

  mounted() {
    const q = this.$route.query?.type
    if (q && typeof q === 'string') {
      this.error = this.errorsMap.get(q as KionErrorType) || this.error
    }
  }
}
