
import { Component, Vue } from 'vue-property-decorator'
import { httpService } from '@/util/HttpAdapter'
import KionButton from '@/components/Shared/Button.vue'
import KionTemplate from '@/components/Shared/Template.vue'
import KionInput from '@/components/Shared/Input/Input.vue'
import { AxiosResponse } from 'axios'

@Component({
  components: {
    KionTemplate,
    KionButton,
    KionInput
  }
})
export default class KionPromoError extends Vue {
  code = httpService.promoCode
  codeError: string | null = null
  loading = false

  async checkPromo() {
    this.loading = true
    try {
      httpService.promoCode = this.code
      const promoResp = (await httpService.checkPromo() as AxiosResponse).data

      if (promoResp?.voucher?.status === 'NOT_FOUND') {
        this.loading = false
        this.setCodeErrors('Промокод не найден')
        return
      }
      const subs = promoResp.promoProductPrices

      if (subs.length === 0) {
        await this.$router.push('/error')
        return
      }

      const prices = (await httpService.checkPrice(subs[0].productId) as AxiosResponse)
          .data.productPrices

      if (prices.length === 0) {
        await this.$router.push('/error')
        return
      }

      await httpService.applySubscription(prices[0].discountPrice)
      await this.$router.push('/success')

    } catch (err) {
      this.loading = false
      const errCode = err?.response?.data?.errorCode
      if(errCode === "ObjectAlreadyExistsException") {
        await this.$router.push('/error')
        console.error('Промокод уже был активирован')
        return
      }
      await this.$router.push('/error')
    }
  }

  setCodeErrors(error?: string) {
    this.code = this.code.trim()
    if (error) {
      this.codeError = error
      return
    }

    if (!this.code) {
      this.codeError = 'Обязательное поле'
      return
    }

    this.codeError = null
  }
}
