
import { Component, Vue } from 'vue-property-decorator'
import KionCopyright from '@/components/Shared/Copyright.vue'
import KionTitle from '@/components/Shared/Title.vue'

@Component({
  components: {
    KionTitle,
    KionCopyright
  }
})
export default class KionTemplate extends Vue {
}
