
import KionTemplate from '@/components/Shared/Template.vue'
import KionButton from '@/components/Shared/Button.vue'
import { httpService } from '@/util/HttpAdapter'
import { Component, Vue } from 'vue-property-decorator'
import { AxiosResponse } from 'axios';
import { KionErrorType } from '@/views/Error.vue';

@Component({
  components: {
    KionButton,
    KionTemplate
  }
})
export default class KionSuccess extends Vue {
  loading = true
  code: null | string = httpService.promoCode

  mounted() {
    this.subscribe()
  }

  async subscribe() {
    try {
      const promoResp = (await httpService.checkPromo() as AxiosResponse).data;

      if (promoResp?.voucher?.status === 'NOT_FOUND') {
        await this.$router.push('/promo');
        return;
      }
      const subs = promoResp.promoProductPrices;

      if (subs.length === 0) {
        await this.$router.push('/error');
        return;
      }

      const prices = (await httpService.checkPrice(subs[0].productId) as AxiosResponse)
        .data.productPrices;

      if (prices.length === 0) {
        await this.$router.push('/error');
        return;
      }

      await httpService.applySubscription(prices[0].discountPrice);
      this.loading = false
    } catch (err) {
      const errCode = err?.response?.data?.errorCode;

      if (err === 'PromoCodeNotFoundException') {
        await this.$router.push('/promo');
        return;
      }

      if (errCode === 'ObjectAlreadyExistsException') {
        await this.$router.push('/error');
        console.error('Промокод уже был активирован');
        return;
      }

      // if (errCode === 'InvalidConfirmationCodeException') {
      //   this.getSMSInputError('Неверный код. Попробуйте ещё раз');
      //   return;
      // }

      if (errCode === 'SubscriberNotFoundException') {
        await this.$router.push(`/error?&type=${KionErrorType.ACCOUNT_NOT_FOUND}`);
        return;
      }

      console.error(errCode);
      await this.$router.push('/error');
    }
  }

  moveTo(link: string) {
    window.location.href = link
  }
}
