import Vue from 'vue'
import { httpService } from '@/util/HttpAdapter'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import KionCode from '@/views/Code.vue'
import KionSms from '@/views/Sms.vue'
import KionSuccess from '@/views/Success.vue'
import KionError from '@/views/Error.vue'
import KionPromoError from '@/views/PromoError.vue'
import KionSsoAuth from '@/views/SsoAuth.vue'
// import KionDebug from '@/views/Debug.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: KionCode
  },
  {
    path: '/sms',
    component: KionSms,
    beforeEnter: codeGuard
  },
  {
    path: '/promo',
    component: KionPromoError,
    beforeEnter: codeGuard
  },
  {
    path: '/success',
    component: KionSuccess,
    // beforeEnter: codeGuard
  },
  {
    path: '/ssoauth',
    component: KionSsoAuth,
  },
]

// Не ясно почем при сборке в прод все равно подтягивается этот компонент
// Так что лучше оставлять закомментированным
// if (process.env.NODE_ENV === 'development') {
//   routes.push({
//     path: '/debug',
//     component: KionDebug
//   })
// }

routes.push(
  {
    path: '*',
    component: KionError
  }
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function codeGuard(to: Route, from: Route, next: NavigationGuardNext) {
  httpService.promoCode ? next() : next('/')
}

export default router
